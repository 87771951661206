/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */


/* TODO: FIXME: 
* PRIME NG Treetable modul miatt angular json-be be volt húzva ez: "node_modules/primeng/resources/themes/bootstrap4-light-blue/theme.css", viszont a naptár CSS-ét felülírja. 
* Ha kell Treetable valamikor, akkor máshogy kell megoldani
*/

@import './prime-custom.scss';
html .footer-fixed .content.app-content {
    padding-bottom: 2.5rem;
}

// PRINT
@media print {
    app-root>* {
        display: none !important;
    }
    app-root app-print-layout {
        display: block !important;
    }
}

.ng-invalid.ng-touched {
    border-color: $danger;
}

.datatable-body-cell-label {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}

.brand-text {
    color: $primary;
    padding-left: 1rem;
    margin-bottom: 0;
    font-weight: 600;
    letter-spacing: 0.01rem;
    font-size: 1.45rem;
    animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
}

.text-danger {
    color: #ea5455 !important;
}

.default-layout tr.selected {
    background-color: #f6f6f9;
}

.dark-layout tr.selected {
    background-color: #242b3d;
}

.modal-header.custom-h {
    height: 38px !important;
}

.modal-footer {
    padding: 0.25rem;
    .btn-block {
        margin: 0;
    }
}

// Label *
.required-asterisk {
    color: red;
    font-weight: bold;
    margin-left: 3px;
    font-size: 1.2em;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    left: 0;
    top: 25% !important;
    position: absolute;
    width: 100%;
}

// Touchspin bg color
.touchspin-wrapper .form-control {
    background-color: white !important;
}

table th#toolbar {
    padding-left: 0.72rem !important;
    padding-right: 0.72rem !important;
}

button:disabled {
    cursor: not-allowed;
    svg {
        cursor: not-allowed;
    }
}

.form-group.row label,
.form-group.flex-row label {
    line-height: 37px !important;
    margin: 0px !important;
}

form.form-with-table {
    min-width: 850px;
}

.ng-select {
    margin-right: 0px !important;
}

.overflow-y-auto {
    overflow-y: auto !important;
}

textarea.no-resize {
    line-height: 1 !important;
    resize: none !important;
}

app-kimeno-szamla .modal-body,
app-dijbekero-eloleg-kimeno .modal-body,
app-ajanlat .modal-body,
app-bejovo-szamla .modal-body,
app-ertekesites .modal-body {
    padding: 0 !important;
}

app-bevetelezes,
app-raktar-korrekcio .details-wrapper {
    min-width: 918px !important;
}

.bt-0 {
    border-top: 0 !important;
}

.ngx-datatable.footer-m-0 datatable-footer.datatable-footer {
    margin-bottom: 0px !important;
}

core-touchspin button.btn {
    margin-top: 2px !important;
}

app-dijbekero-eloleg-kimeno ngx-datatable.bootstrap .visible .datatable-body {
    height: 323px !important;
}

app-bejovo-szamla ngx-datatable.bootstrap .visible .datatable-body {
    height: 315px !important;
}

app-bizonylatok-toolbar ng2-flatpickr .ng2-flatpickr-input.form-control.input {
    width: 120px !important;
}

app-torzsek-list ngx-datatable.bootstrap .visible .datatable-body {
    height: calc(100vh - 360px) !important;
    width: calc(100vw - 356px) !important;
}

app-utalasi-jegyzek ngx-datatable.bootstrap .visible .datatable-body {
    height: calc(100vh - 19rem) !important;
    width: 100%;
    max-width: calc(100vw - 300px) !important;
}

.border-left-success {
    border-left: 3px solid #28c76f !important;
    padding-left: 0.75rem !important;
}

.border-left-warning {
    border-left: 3px solid #ff9f43 !important;
}

.border-left-danger {
    border-left: 3px solid #ea5455 !important;
}

.container-xxl {
    max-width: 100% !important;
}

// Light layout
.default-layout {
    // Header cell
    .datatable-row-right {
        background-color: #f3f2f7 !important;
    }
    // Row cell
    .datatable-row-group.datatable-row-right {
        // background-color: #f3f2f7 !important;
        background-color: white !important;
    }
}

// Dark layout
.dark-layout {
    // Header cell
    .datatable-row-right {
        background-color: #343d55 !important;
    }
    // Row cell
    .datatable-row-group.datatable-row-right {
        // background-color: #f3f2f7 !important;
        background-color: #283046 !important;
    }
    .dijbekero-eloleg-kimeno-container {
        .top {
            border-bottom: 1px solid #161d31 !important;
            .center {
                grid-area: 1/2/2/3;
                border-left: 1px solid #161d31 !important;
                border-right: 1px solid #161d31 !important;
            }
        }
        .footer-wrapper {
            border-top: 1px solid #161d31 !important;
        }
    }
    .modal-header {
        border-bottom: 1px solid #161d31 !important;
        &.bg-white {
            background-color: #283046 !important;
        }
    }
    span.form-control {
        background-color: #283046 !important;
        border-left: 1px solid #3b4253 !important;
        border-top: 1px solid #3b4253 !important;
        border-bottom: 1px solid #3b4253 !important;
    }
    .touchspin-wrapper .form-control {
        background-color: #283046 !important;
        // border: 1px solid #3b4253 !important;
    }
    .table-container thead tr th {
        background-color: #283046 !important;
    }
    app-ajanlat {
        .left {
            border-right: 0.5px solid #161d31 !important;
        }
    }
}

// Torzsek list table
.ngx-datatable.bootstrap.core-bootstrap .datatable-body .datatable-body-row .datatable-row-right.datatable-row-group {
    margin-left: 16px !important;
}

.modal-dialog.print-content {
    height: 297mm !important;
    width: 210mm !important;
    max-height: 297mm !important;
    max-width: 210mm !important;
}

// Quill editor
.ql-container.ql-snow {
    border-bottom: 0px !important;
}

.ql-toolbar.ql-snow {
    border-top: 0px !important;
}

// Full calendar popover
.fc .fc-popover {
    z-index: 1049 !important;
}

.fc-daygrid-event-dot {
    border: 4px solid white !important;
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
    display: none;
}

.default-layout .ng-select.ng-select-disabled>.ng-select-container {
    background-color: #efefef !important;
}

ngb-modal-window.ertekesites-modal>.modal-dialog.modal-xxl {
    margin: auto;
    max-width: calc(100vw - 1rem);
    width: 1685px;
    padding: 0.25rem;
}

// NG key shortcuts help modal
.help-modal__container {
    z-index: 9999 !important;
}

app-partnerek .form-group.row>div:last-child {
    display: flex !important;
    margin: auto 0px !important;
}

.py-1rem {
    padding: 1rem 0px;
}

.form.form-horizontal .form-group {
    margin-bottom: 0px;
}

// .btn-outline-custom.dropdown-toggle :focus {
//   border-color: #42a3af !important;
//   color: #42a3af !important;
// }
// .btn-outline-custom.dropdown-toggle ::after {
//   background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaBAMAAABbZFH9AAAAG1BMVEUAAACRkZGRkZGSkpKRkZGSkpKSkpKRkZGRkZHLso+9AAAACHRSTlMA+1JoWo0vLFQDmmkAAABlSURBVBjTY6ALSACTbBAOazOYsggAUxEdBkCSuaMVxGGX6BABUo4djQUgrmJHhwFQqkMIrJJJoqOZwaKjUQHIhkg6g6QggEWiQ7Cj0QHIgkpCpaA6wbrgkiAphKSgArJTXRhoBgB9GRPswyvBqAAAAABJRU5ErkJggg==);
//   background-size: 12px 12px, 10px 10px;
//   background-repeat: no-repeat;
//   height: 0.8rem !important;
//   padding-right: 1.5rem;
//   margin-left: 0;
//   margin-top: 0;
//   left: 0;
//   border-style: none !important;
// }
select.my-select {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    -webkit-appearance: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaBAMAAABbZFH9AAAAG1BMVEUAAACRkZGRkZGSkpKRkZGSkpKSkpKRkZGRkZHLso+9AAAACHRSTlMA+1JoWo0vLFQDmmkAAABlSURBVBjTY6ALSACTbBAOazOYsggAUxEdBkCSuaMVxGGX6BABUo4djQUgrmJHhwFQqkMIrJJJoqOZwaKjUQHIhkg6g6QggEWiQ7Cj0QHIgkpCpaA6wbrgkiAphKSgArJTXRhoBgB9GRPswyvBqAAAAABJRU5ErkJggg==);
    background-size: 12px 12px, 10px 10px;
    background-repeat: no-repeat;
    height: 0.8rem !important;
    padding-right: 1.5rem;
    margin-left: 0;
    margin-top: 0;
    left: 0;
    color: #999;
    &.ng-valid.ng-dirty:focus,
    &.ng-invalid.ng-touched:focus {
        border-color: #42a3af !important;
    }
    option {
        height: 24px;
        padding: 8px 10px;
    }
}

.input-group:not(.bootstrap-touchspin):focus-within .ng-valid,
.input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
    border-color: #42a3af !important;
    box-shadow: none;
}

.input-group:not(.bootstrap-touchspin):focus-within .form-control.ng-valid.ng-dirty~.input-group-append .input-group-text,
.input-group:not(.bootstrap-touchspin):focus-within .input-group-text.ng-valid.ng-dirty~.input-group-append .input-group-text {
    border-color: #42a3af !important;
}

.input-group:not(.bootstrap-touchspin):focus-within .ng-invalid.ng-touched {
    border-color: #ea5455 !important;
}

.default-layout .form-control:disabled~.input-group-prepend>.input-group-text,
.default-layout .form-control:disabled~.input-group-append>.input-group-text,
.default-layout .form-control[readonly]~.input-group-prepend>.input-group-text,
.default-layout .form-control[readonly]~.input-group-append>.input-group-text,
.default-layout .form-control:disabled~.input-group-append>.input-group-text {
    background-color: #efefef;
    opacity: 1;
}

.flex.flex-row {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.default-layout .torzsek-toolbar-container {
    background: white;
}

.dark-layout .torzsek-toolbar-container,
.dark-layout .content-area-wrapper {
    background: #283046 !important;
}

.vertical-divider {
    border-left: 1px solid #d8d6de;
}

.dark-layout .form-control.ng-valid {
    border-color: #3b4253 !important;
}

.dark-layout .form-control:not(.ng-invalid) {
    background-color: #283046;
    color: #b4b7bd;
    border-color: #3b4253;
}

.default-layout .footer-wrapper {
    border-top: 1px solid #ebe9f1;
}

.dark-layout .footer-wrapper {
    border-top: 1px solid #3b4253;
}

.default-layout .card-header {
    border-bottom: 1px solid #ebe9f1;
}

.dark-layout .card-header {
    border-bottom: 1px solid #3b4253;
}

.dark-layout .todo-task-list-wrapper {
    background-color: #283046 !important;
    border-color: #3b4253;
}

.dark-layout .todo-task-list .todo-item {
    &:hover {
        box-shadow: 0 4px 24px #22292f3d;
    }
    &:not(:first-child) {
        border-top: 1px solid #3b4253 !important;
    }
}

label.required-asterix:after {
    content: '*';
    color: red;
    font-size: 14px;
}

.fc.fc-theme-standard .fc-toolbar {
    .fc-prev-button,
    .fc-next-button {
        background: inherit;
        border: inherit;
    }
}

form.calendar-container {
    min-width: 345px;
}

.main-menu.menu-light .navigation>li.active>a {
    color: #161d31 !important;
}

.main-menu.menu-light .navigation>li ul .active>a {
    color: #161d31 !important;
}

.btn-primary {
    color: #6e6b7b !important;
}

.brand-text {
    color: #231f20;
    -webkit-text-stroke: 0.1px #161d31;
}

.text-primary {
    color: #6e6b7b !important;
}

.dark-layout .text-primary {
    color: #fbd81a !important;
}