$textLightColor: #6e6b7b;
$textDarkColor: #b4b7bd;
// $textPrimaryColor: #42a3af;
$textPrimaryColor: #fbd81a;
$textPrimaryBg: rgba(115, 103, 240, 0.04);
$lightBackground: #f8f8f8;
$lightBorder: #ebe9f1;
$darkBorder: #3b4253;
$darkBg: #283046;
.p-component {
    font-family: 'Montserrat', Helvetica, Arial, serif !important;
    color: $textLightColor;
}

.p-datatable .p-sortable-column:focus,
.p-treetable .p-sortable-column:focus {
    box-shadow: none !important;
    outline: 0 none;
}

// Table header
.p-datatable-thead>tr>th,
.p-treetable .p-treetable-thead>tr>th {
    letter-spacing: 0.5px;
    font-size: 0.857rem;
    font-weight: bold;
    color: $textLightColor;
    padding: 0.75rem 1rem;
    font-family: 'Montserrat', Helvetica, Arial, serif;
}

// Table cell
.p-datatable-tbody>tr>td,
.p-treetable .p-treetable-tbody>tr>td {
    padding: 0.75rem;
    font-size: 1rem;
    padding: 0.75rem 1rem;
    font-family: 'Montserrat', Helvetica, Arial, serif;
    span {
        white-space: nowrap !important;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 288px;
    }
}

.default-layout .p-datatable .p-datatable-tbody>tr {
    color: $textLightColor;
}

.dark-layout .p-datatable .p-datatable-tbody>tr {
    color: $textDarkColor;
}

// Light header
.default-layout .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th,
.default-layout .p-treetable .p-treetable-thead>tr>th {
    background-color: $lightBackground;
    border-color: $lightBorder;
    color: $textLightColor;
}

// Light cell
.default-layout .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td,
.default-layout .p-treetable .p-treetable-tbody>tr>td {
    border-color: $lightBorder;
    color: $textLightColor;
}

// Dark header
.dark-layout .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th,
.dark-layout .p-treetable .p-treetable-thead>tr>th {
    background-color: $darkBg;
    border-color: $darkBorder;
    color: #d0d2d6;
}

// Dark cell
.dark-layout .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td,
.dark-layout .p-treetable .p-treetable-tbody>tr>td {
    border-color: $darkBorder;
    color: $textDarkColor;
    background-color: $darkBg;
}

.dark-layout .p-datatable.p-datatable-gridlines.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):hover {
    background: #161f31;
    color: #ffffffde;
}

.p-datepicker-calendar th {
    text-align: center;
}

.p-inputtext:enabled:focus {
    box-shadow: none !important;
    border-color: $textPrimaryColor !important;
}

.dark-layout .p-paginator {
    background: $darkBg;
}

.default-layout .p-paginator .p-paginator-current,
.default-layout .p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.default-layout .p-paginator .p-paginator-pages .p-paginator-page,
.default-layout .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover,
.default-layout .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    color: $textPrimaryColor;
}

.p-dropdown-label {
    color: $textPrimaryColor
}

.p-dropdown-trigger {
    color: $textPrimaryColor !important;
}

.dark-layout .p-paginator .p-paginator-current,
.dark-layout .p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.dark-layout .p-paginator .p-paginator-pages .p-paginator-page,
.dark-layout .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover,
.dark-layout .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    color: $textPrimaryColor;
}

// PrimeNG Table footer
.default-layout .p-datatable-footer {
    display: flex !important;
    height: 40px;
    align-items: center;
    border-top: 1px solid $lightBorder;
    border-left: 1px solid $lightBorder;
    background: $lightBackground;
}

.dark-layout .p-datatable-footer {
    display: flex !important;
    height: 40px;
    align-items: center;
    border-top: 1px solid $darkBorder;
    border-left: 1px solid $darkBorder;
    background-color: $darkBg !important;
    color: #d0d2d6 !important;
}

.default-layout .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: $textPrimaryColor;
    border-color: $textPrimaryColor;
    color: #6e6b7b;
}

.dark-layout .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: $textPrimaryColor;
    border-color: $textPrimaryColor;
    color: $textDarkColor;
}

// Table pager
.p-link:focus:not(.p-column-filter-menu-button) {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}

.p-link.p-column-filter-menu-button:focus {
    box-shadow: 0 0 0 0.1rem rgb(67 163 175);
}

.p-column-filter-menu-button.p-column-filter-menu-button-open {
    background: #e9ecef;
}

.p-button,
.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    color: #ffffff;
    background: $textPrimaryColor;
    border-color: $textPrimaryColor;
}

.p-button:enabled:hover {
    background: $textPrimaryColor;
    color: #ffffff;
    border-color: $textPrimaryColor;
    box-shadow: none;
}

.p-button:focus {
    box-shadow: none;
}

.p-button.p-button-text {
    color: $textPrimaryColor;
}

.p-button.p-button-text:enabled:hover {
    color: $textPrimaryColor;
    box-shadow: none;
}

.p-button.p-button-outlined {
    background-color: transparent;
    color: $textPrimaryColor;
    border: 1px solid $textPrimaryColor;
}

.p-button.p-button-outlined:enabled:hover {
    background: $textPrimaryBg;
    color: $textPrimaryColor;
    border: 1px solid $textPrimaryColor;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #ffffff;
    background: $textPrimaryColor;
}

.p-dropdown:not(.p-disabled).p-focus {
    border-color: $textPrimaryColor;
    box-shadow: none;
}

.p-datepicker table td.p-datepicker-today>span.p-highlight,
.p-datepicker table td>span.p-highlight {
    color: #ffffff;
    background: $textPrimaryColor;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
    color: $textPrimaryColor;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: #ffffff;
    background: $textPrimaryColor;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus,
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem rgb(115 103 240 / 70%);
}

.dark-layout .p-datepicker:not(.p-datepicker-inline),
.dark-layout .p-datepicker-header,
.dark-layout .p-datepicker-calendar-container {
    background: $darkBg !important;
}